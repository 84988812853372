import { Button, Col, Form, Row } from 'react-bootstrap'
import classes from './ContactUs.module.scss'
import { useState } from 'react'
import { EnvelopeAt, Instagram, TelephoneOutbound } from 'react-bootstrap-icons'

export const ContactUs = () => {
    const [validated, setValidated] = useState(false)

    const handleSubmit = (event: any) => {
        const form = event.currentTarget
        if (form.checkValidity() === false) {
            event.preventDefault()
            event.stopPropagation()
        } else {
            console.log('email', event.target[0].value)
            console.log('message', event.target[1].value)
        }

        setValidated(true)
    }

    return (
        <div
            className={[classes.ContactUs, 'container text-center p-5'].join(
                ' ',
            )}
        >
            <h1>Have some questions?</h1>
            <Row className="align-items-center mt-5">
                <Col>
                    <img
                        src="https://via.placeholder.com/300"
                        alt="placeholder"
                    />
                    <div className={classes.Info}>
                        <h3>Get in touch</h3>
                        <hr />
                        <p>
                            <TelephoneOutbound />
                            <span className="m-2">+359 87 733 3783</span>
                        </p>
                        <p>
                            <EnvelopeAt />
                            <span className="m-2">sales@flair-digital.xyz</span>
                        </p>
                        <p>
                            <Instagram />
                            <span className="m-2">@franz.fischbach</span>
                        </p>
                    </div>
                </Col>
                <Col>
                    <Form
                        className={classes.Form}
                        noValidate
                        validated={validated}
                        onSubmit={handleSubmit}
                    >
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                        >
                            <Form.Label>Email address</Form.Label>
                            <Form.Control
                                required
                                type="email"
                                placeholder="name@example.com"
                            />
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <Form.Label>Your Message</Form.Label>
                            <Form.Control required as="textarea" rows={3} />
                        </Form.Group>
                        <Button type="submit">Message us</Button>
                    </Form>
                </Col>
            </Row>
        </div>
    )
}
