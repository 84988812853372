import React from 'react'
import { Header } from '../../components/Header/Header'
import classes from './Layout.module.scss'
import { Footer } from '../../components/Footer/Footer'

export const Layout = (props: React.PropsWithChildren) => (
    <div className={classes.Layout}>
        <div className={classes.Content}>
            <Header />
            {props.children}
        </div>
        <Footer />
    </div>
)
