import { ReactNode } from 'react'
import classes from './Card.module.scss'
import { Col, Row } from 'react-bootstrap'

export const Card = (props: {
    leftChild: ReactNode
    rightChild: ReactNode
}) => (
    <Row
        className={[
            classes.Card,
            'align-items-center',
            'shadow-lg rounded',
        ].join(' ')}
    >
        <Col className="text-center">{props.leftChild}</Col>
        <Col className="text-center">{props.rightChild}</Col>
    </Row>
)
