import { Button, Row } from 'react-bootstrap'
import classes from './Home.module.scss'

export const Home = () => {
    return (
        <Row className={classes.Home}>
            <div className={[classes.Text, 'shadow-lg', 'rounded'].join(' ')}>
                <h1>About us</h1>
                <p>
                    Flair Digital is your trusted partner for transforming brand visions into compelling digital realities. With a solid background in live video photoshoots and product photography, we fuse creativity with technical expertise in every project. Our approach seamlessly blends innovative technology with strategic marketing, ensuring your brand shines in the digital realm. We provide a comprehensive suite of solutions tailored to your brand's needs, from live video production to SEO strategies. Join us at Flair Digital, where together, we'll craft the future of your brand.
                </p>
                <Button className="btn-secondary">Contact us</Button>
            </div>
        </Row>
    )
}
