import { useEffect, useState } from 'react'
import classes from './NavBar.module.scss'
import { Container, Nav, Navbar } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'

enum LOCATION {
    HOME = '/',
    PORTFOLIO = '/portfolio',
    CONTACT_US = '/contact-us',
}

export const Header = () => {
    const [activeKey, setActiveKey] = useState(LOCATION.HOME as string);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        location.pathname === '/'
            ? setActiveKey(LOCATION.HOME)
            : setActiveKey(location.pathname);
        console.log('activeKey', activeKey);
    }, [location.pathname, activeKey]);

    const handleClick = (e: any, location: string) => {
        e.preventDefault();
        setActiveKey(location);
        navigate(location);
    };

    return (
        <Navbar expand="md" bg="dark" data-bs-theme="dark">
            <Container>
                <Navbar.Brand href="/" className="ms-3">Flair Digital</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-between flex-grow-1">
                    <Nav className="me-auto"></Nav>
                    <Nav activeKey={activeKey} className="me-3">
                        <Nav.Link
                            eventKey="home"
                            onClick={(e) => handleClick(e, LOCATION.HOME)}
                        >
                            Home
                        </Nav.Link>
                        <Nav.Link
                            eventKey="portfolio"
                            onClick={(e) => handleClick(e, LOCATION.PORTFOLIO)}
                        >
                            Portfolio
                        </Nav.Link>
                        <Nav.Link
                            eventKey="contact-us"
                            onClick={(e) => handleClick(e, LOCATION.CONTACT_US)}
                        >
                            Contact Us
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};