import classes from './Footer.module.scss'

export const Footer = () => (
    <footer className={classes.Footer}>
        <div className="container">
            <p>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Totam
                quidem molestiae repudiandae ab accusantium dicta iure cum
                cupiditate, iste sit at minima, pariatur harum eveniet eaque,
                mollitia enim cumque laudantium?
            </p>
        </div>
    </footer>
)
