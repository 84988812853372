import { Route, Routes } from 'react-router-dom'
import classes from './App.module.scss'
import { Home } from './containers/Home/Home'
import { Layout } from './containers/Layout/Layout'
import { Portfolio } from './containers/Portfolio/Portfolio'
import { ContactUs } from './containers/ContactUs/ContactUs'

function App() {
    return (
        <div className={classes.App}>
            <Layout>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/portfolio" element={<Portfolio />} />
                    <Route path="/contact-us" element={<ContactUs />} />
                    <Route path="*" element={<Home />} />
                </Routes>
            </Layout>
        </div>
    )
}

export default App
