import { Card } from '../../components/Card/Card'

export const Portfolio = () => {
    const urls = [
        'https://www.youtube.com/embed/DfKSoPzsAiw',
        'https://www.youtube.com/embed/DfKSoPzsAiw',
        'https://www.youtube.com/embed/DfKSoPzsAiw',
    ]

    return (
        <div className="container mt-3 mb-3">
            <Card
                leftChild={
                    <p>
                        Lorem ipsum dolor sit, amet consectetur adipisicing
                        elit. Totam quidem molestiae repudiandae ab accusantium
                        dicta iure cum cupiditate, iste sit at minima, pariatur
                        harum eveniet eaque, mollitia enim cumque laudantium?
                    </p>
                }
                rightChild={
                    <iframe
                        width="560"
                        height="315"
                        src={urls[0]}
                        referrerPolicy="strict-origin-when-cross-origin"
                        title="YouTube video player"
                        allow="accelerometer;encrypted-media;gyroscope;"
                        allowFullScreen
                    />
                }
            />
            <Card
                leftChild={
                    <iframe
                        width="560"
                        height="315"
                        src={urls[1]}
                        referrerPolicy="strict-origin-when-cross-origin"
                        title="YouTube video player"
                        allow="accelerometer;encrypted-media;gyroscope;"
                        allowFullScreen
                    />
                }
                rightChild={
                    <p>
                        Lorem ipsum dolor sit, amet consectetur adipisicing
                        elit. Totam quidem molestiae repudiandae ab accusantium
                        dicta iure cum cupiditate, iste sit at minima, pariatur
                        harum eveniet eaque, mollitia enim cumque laudantium?
                    </p>
                }
            />
            <Card
                leftChild={
                    <p>
                        Lorem ipsum dolor sit, amet consectetur adipisicing
                        elit. Totam quidem molestiae repudiandae ab accusantium
                        dicta iure cum cupiditate, iste sit at minima, pariatur
                        harum eveniet eaque, mollitia enim cumque laudantium?
                    </p>
                }
                rightChild={
                    <iframe
                        width="560"
                        height="315"
                        src={urls[2]}
                        referrerPolicy="strict-origin-when-cross-origin"
                        title="YouTube video player"
                        allow="accelerometer;encrypted-media;gyroscope;"
                        allowFullScreen
                    />
                }
            />
        </div>
    )
}
